<script setup>
import { ref, provide, onMounted } from "vue";
import { Head } from '@inertiajs/vue3';
import Navbar from '@/Layouts/Navbar.vue';
import Sidebar from '@/Layouts/Sidebar.vue';
import CheckIfAuth from "@/Pages/Auth/CheckIfAuth.vue";
import CheckIfMaintenanceAdmin from "@/Pages/Admin/Database/CheckMaintenance.vue";
import { notify, Notification, NotificationGroup } from "notiwind";
import { InformationCircleIcon, CheckIcon } from "@heroicons/vue/24/solid";
defineProps({
    title: String,
    headerStyle: {
        type: String,
        default: 'bg-white shadow',
    },
});



let sideBarOpen = ref(false);
provide("sideBarOpen", sideBarOpen);

let updatedNotif = ref("");
provide("updatedNotif", updatedNotif);
</script>

<template>
    <div class="leading-normal tracking-normal" id="main-body">
        <div class="flex flex-wrap">

            <Head :title="__(title)" />

            <Sidebar v-if="$page.props.auth.user && $page.props.auth.user.active" />

            <div class="w-full bg-gray-100 pl-0 min-h-screen" :class="$page.props.auth.user && $page.props.auth.user.active ? 'lg:pl-64' : ''" id="main-content">

                <Navbar />

                <!-- Page Heading -->
                <header v-if="$slots.header" :class="headerStyle">
                    <div class="mx-auto py-6 px-4 sm:px-6 lg:px-8">
                        <slot name="header" />
                    </div>
                </header>

                <!-- Page Content -->
                <main>
                    <CheckIfMaintenanceAdmin />
                    <CheckIfAuth v-if="$page.props.auth.user" />
                    <slot />
                </main>

                <NotificationGroup group="mytoast" position="bottom">
                    <div class="fixed inset-x-0 bottom-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none">
                        <div class="w-full max-w-sm">
                            <Notification v-slot="{ notifications }" enter="ease-out duration-300 transition" enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                                enter-to="translate-y-0 opacity-100 sm:translate-x-0" leave="transition ease-in duration-500" leave-from="opacity-100" leave-to="opacity-0"
                                move="transition duration-500" move-delay="delay-300">
                                <div v-for="notification in notifications" :key="notification.id" class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md">
                                    <div v-if="notification.type === 'error'" class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md">
                                        <div class="flex items-center justify-center w-12 bg-red-500">
                                            <svg class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                                            </svg>
                                        </div>

                                        <div class="px-4 py-2 -mx-3">
                                            <div class="mx-3">
                                                <span class="font-semibold text-red-500">{{ notification.title }}</span>
                                                <p class="text-sm text-gray-600">
                                                    {{ notification.text }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="notification.type === 'info'" class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md">
                                        <div class="flex items-center justify-center w-12 bg-blue-500">
                                            <InformationCircleIcon class="w-6 h-6 text-white fill-current" />
                                        </div>

                                        <div class="px-4 py-2 -mx-3">
                                            <div class="mx-3">
                                                <span class="font-semibold text-blue-500">{{ notification.title }}</span>
                                                <p class="text-sm text-gray-600">
                                                    {{ notification.text }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="notification.type === 'success'" class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md">
                                        <div class="flex items-center justify-center w-12 bg-green-500">
                                            <CheckIcon class="w-6 h-6 text-white fill-current" />
                                        </div>

                                        <div class="px-4 py-2 -mx-3">
                                            <div class="mx-3">
                                                <span class="font-semibold text-green-500">{{ notification.title }}</span>
                                                <p class="text-sm text-gray-600">
                                                    {{ notification.text }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Notification>
                        </div>
                    </div>
                </NotificationGroup>
            </div>

        </div>

    </div>
</template>
